import React from 'react'
import Layout from "../../components/Layout"
import Input from "../../components/Input"
import SvgIcon from "../../components/SvgCurve"
import { Link } from 'gatsby'
import StepCart from '../../components/StepCart'
import { navigate } from "gatsby"

export default function LoginCart(props) {
    const { path } = props

    const redirectPage = () => {
        return navigate("/cesta/buy/")
    }

    const redirectPageRegister = () => {
        return navigate("/cesta/register/")
    }

    return (
        <Layout>
            <div className="LoginCart">
                <div className="LoginCart-container">
                    <div className="row">
                        <div className="col-12">
                            <StepCart path={path} />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="LoginCart-card">
                                <span className='LoginCart-title'>
                                    <SvgIcon className="LoginCart-icon" icon="userCart" />
                                    Si ya eres usuario de Laie
                                </span>
                                <span className="LoginCart-text">Accede a tu cuenta</span>
                                <form action="">
                                    <Input className="LoginCart-input" label="E-mail" type="email" />
                                    <Input className="LoginCart-input" label="Contraseña" type="password" />
                                    <Link to="/contrasena-recordar" className="LoginCart-link">¿Has olvidado tu contraseña?</Link>
                                </form>
                                <button onClick={redirectPage} className="LoginCart-btn">Acceder</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="LoginCart-card">
                                <span className='LoginCart-title'>
                                    <SvgIcon className="LoginCart-icon" icon="userCart" />
                                    Si eres un usuario nuevo
                                </span>
                                <span className="LoginCart-text">Introduce tu e-mail para continuar con tu compra. Podrás registrarte y guardar tus datos para futuras compras.</span>
                                <form onSubmit={(e) => {e.preventDefault()}}>
                                    <Input className="LoginCart-input" label="Email" type="email" />
                                    <button onClick={redirectPageRegister} className="LoginCart-btn">Crear Cuenta</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
